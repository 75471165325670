<template>
  <v-row class="pa-0 ma-0 help-row">
    <v-col lg="12" md="12" xl="12">
      <v-card class="help-card mt-10">
        <h2 class="font-family-raleway-medium">
          {{ $t("faq_page.request_card_title") }}
        </h2>
        <v-form ref="form" v-model="valid">
          <v-row class="mt-2 f-col">
            <v-col md="3">
              <v-text-field
                v-model="name"
                class="border-16"
                :label="$t('faq_page.request_card_name_input')"
                :rules="rules"
                outlined
                required
              />
            </v-col>
            <v-col md="3">
              <v-text-field
                v-model="email"
                :label="$t('faq_page.request_card_email_input')"
                class="border-16"
                :rules="emailRules"
                required
                outlined
              />
            </v-col>
            <v-col md="6">
              <v-textarea
                v-model="message"
                class="border-16"
                :label="$t('faq_page.request_card_message_input')"
                :rows="3"
                outlined
                :rules="rules"
                required
              />
            </v-col>
          </v-row>
          <div class="d-flex justify-space-between mt-5 fld-col ">
            <p class="pb-0 mb-2 font-family-raleway-medium d-flex t-smaller">
              <v-simple-checkbox
                class="align-self-baseline"
                v-model="checkbox"
                color="indigo"
                required
              ></v-simple-checkbox>
              <span style="white-space: normal">
                {{ $t("faq_page.agree_terms_text") }}
              <a
                class="font-family-raleway-medium"
                href="/terms-of-use"
                target="_blank"
              >
                {{ $t("login_card.agree_terms_text_link") }} |
              </a>
              <a
                class="font-family-raleway-medium"
                href="/privacy-policy"
                target="_blank"
              >
                {{ $t("login_card.agree_policy_text_link") }}</a
              >
              </span>

            </p>
            <v-btn
              class="button font-family-raleway-bold"
              width="500"
              height="55"
              @click="sendMessage"
              :loading="loading"
            >
              {{ $t("faq_page.contact_now_button") }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { authService } from "@/services/auth";

export default {
  name: "Help",
  data() {
    return {
      checkbox: false,
      valid: false,
      loading: false,
      email: "",
      name: "",
      message: "",
      rules: [(v) => !!v || this.$t("required_text")],
      emailRules: [
        (v) => !!v || this.$t("required_text"),
        (v) => /.+@.+\..+/.test(v) || this.$t("email_must_valid"),
      ],
      questions: [
        {
          title: this.$t("faq_page.questions1"),
          answer:
            "In order to access the Immersive European Ophthalmology Exams Preparatory Course, you will need to register and ",
          isClicked: false,
        },
        {
          title: this.$t("faq_page.questions2"),
          answer:
            "In order to access the Immersive European Ophthalmology Exams Preparatory Course, you will need to register and",
          isClicked: false,
        },
        {
          title: this.$t("faq_page.questions3"),
          answer:
            "In order to access the Immersive European Ophthalmology Exams Preparatory Course, you will need to register",
          isClicked: false,
        },
        {
          title: this.$t("faq_page.questions4"),
          answer:
            "In order to access the Immersive European Ophthalmology Exams Preparatory Course, you will need to register and ",
          isClicked: false,
        },
        {
          title: this.$t("faq_page.questions5"),
          answer:
            "In order to access the Immersive European Ophthalmology Exams Preparatory Course, you will need to register and ",
          isClicked: false,
        },
        {
          title: this.$t("faq_page.questions6"),
          answer:
            "In order to access the Immersive European Ophthalmology Exams Preparatory Course, you will need to register and ",
          isClicked: false,
        },
      ],
    };
  },
  methods: {
    clickPrivacy() {},
    clickTerms() {},
    handleClick(ind) {
      const findItem = this.questions.find((item, index) => index === ind);
      findItem.isClicked = !findItem.isClicked;
      this.questions.forEach((item, index) => {
        if (index !== ind) {
          item.isClicked = false;
        }
      });
    },
    async sendMessage() {
      await this.$refs.form.validate();
      if (this.valid) {
        const payload = {
          email: this.email,
          name: this.name,
          message: this.message,
        };
        this.loading = true;
        try {
          await authService.sendMessage(payload);
          this.$notify({
            group: "foo",
            type: "success",
            title: "Thank you!",
            text: "Your message has been successfully sent. We will contact you very soon!",
          });
        } catch (e) {
          const errorText = e.response?.statusText;
          this.$notify({
            group: "foo",
            type: "error",
            title: errorText || "Something went wrong!",
          });
        }
        this.$refs.form.reset();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.help-row {
  scale: 0.9 !important;
}

.help-card {
  padding: 40px;
  box-shadow: none !important;
  border-radius: 12px;
  @media screen and (max-width: 600px) {
    padding: 20px 15px;
  }
  @media screen and (max-width: 450px) {
    padding: 20px 10px;
  }
}
@media screen and (max-width: 600px) {
  .f-col{
    flex-direction: column!important;
  }
  .button{
    width: 100%!important;
  }
  .t-smaller{
    font-size: 15px!important;
  }
}

@media screen and (max-width: 960px) {
  .fld-col{
    flex-direction: column;
  }
}
</style>
